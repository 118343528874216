
import CommissionRule from '@/models/CommissionRule'
import ViewModel from '@/models/ViewModel'

import {
  Component, Prop, Ref, Vue, Watch,
} from 'vue-property-decorator'
import moment from 'moment'
import WebMessage from '@/models/WebMessage'
import { capitalize } from 'lodash'
import PreviewCommissionRule from './PreviewCommissionRule.vue'

@Component({
  components: {
    PreviewCommissionRule,
  },
})
export default class CommissionSubTable extends ViewModel {
  @Ref() readonly subTable!: any

  @Prop({ default: () => [] })
  public rules!: any

  @Prop({ default: () => [] })
  public fields!: any

  public temp_commission_rule: CommissionRule = new CommissionRule()

  public preview_rule: boolean = false

  public get show_fields() {
    return this.fields.filter((f: any) => f.show)
  }

  public get items() {
    if (!this.rules) return []
    return this.rules
  }

  public set items(val: any) {
    this.$emit('update:rules', val)
  }

  public get item_list() {
    return this.items
  }

  public getMonthName(index: number) {
    return moment().month(index).format('MMM')
  }

  public previewRule(rule: CommissionRule) {
    this.temp_commission_rule = rule
    setTimeout(() => {
      this.preview_rule = true
    }, 200)
  }

  public deleteRule(item: CommissionRule) {
    WebMessage.confirm('Are you sure you want to delete this rule?', 'Delete commission Rule').then(
      (value: boolean) => {
        if (value) {
          item.delete().then(() => {
            this.$emit('refresh')
          })
        }
      },
    )
  }

  public getTargetType(row: any) {
    let type = row.item.commissionable_type
      ? row.item.commissionable_type.replace('App\\Models\\', '')
      : 'any'

    if (type === 'Company') {
      return 'Business Entity'
    }

    return type
  }
}
