
import { Component, Ref, Vue } from 'vue-property-decorator'
import Widget from '@/components/Widget/Widget.vue'
import { FormWizard, TabContent } from 'vue-form-wizard'
import FormInput from '@/components/FormInput/FormInput.vue'
import { getModule } from 'vuex-module-decorators'
import SystemtModule from '@/store/SystemModule'
import ViewModel from '@/models/ViewModel'
import DataTable from '@/components/DataTable/index.vue'
import SearchInput from '@/components/SearchInput/SearchInput.vue'
import IconAction from '@/components/IconAction/IconAction.vue'
import CommissionRuleGroup from '@/models/CommissionRuleGroup'
import CommissionHomeTable from './commission-rules-table'
import CommissionSubTable from './Components/CommissionSubTable.vue'

@Component({
  components: {
    Widget,
    FormWizard,
    TabContent,
    FormInput,
    DataTable,
    SearchInput,
    IconAction,
    CommissionSubTable,
  },
})
export default class CommissionRules extends ViewModel {
  @Ref() readonly dataTable!: HTMLFormElement

  public fields: any = []

  public loading: boolean = false

  public busy = false

  public query: string[] = ['is_not:archived']

  public fieldFilters: any = {}

  public ready: boolean = false

  public records: number = 0

  public selected: string[] = []

  public query_settings: any = {
    user_fields: [
      {
        name: 'user',
        key: 'user',
      },
    ],
    custom_fields: [
      {
        name: 'is_not:archived',
        value: 'is_not:archived',
      },
    ],
  }

  public group_list: any = []

  public home_fields: any = [
    {
      key: 'user_id',
      label: 'User',
      sortable: true,
      class: 'text-center align-middle text-capitalize',
      show: true,
      tdClass: 'align-middle text-center',
    },
    {
      key: 'type',
      label: 'Type',
      sortable: true,
      class: 'text-center align-middle text-capitalize',
      show: true,
      filter: false,
      tdClass: 'align-middle text-center',
    },
    {
      key: 'total_rules',
      label: 'Total Rules',
      sortable: true,
      class: 'text-center align-middle text-capitalize',
      show: true,
      filter: false,
      tdClass: 'align-middle text-center',
    },
    {
      key: 'action',
      label: '',
      sortable: false,
      class: 'text-center align-middle text-capitalize',
      show: true,
      type: 'action',
      tdClass: 'align-middle text-center',
    },
  ]

  public get show_fields() {
    return this.home_fields.filter((f: any) => f.show)
  }

  public get sub_table_fields() {
    return this.fields
  }

  public mounted() {
    this.fields = CommissionHomeTable
    this.loadFilters()
  }

  public async rows(ctx: any): Promise<any> {
    this.loading = true

    const field_filters = Object.keys(this.fieldFilters)
      .filter((key: string) => this.fieldFilters[key] !== '')
      .map((key: string) => `${key}:${this.fieldFilters[key].toLowerCase()}`)

    this.syncFilters()

    return CommissionRuleGroup.paginate({
      page_size: ctx.perPage,
      page: ctx.currentPage,
      order_by: ctx.sortBy,
      order: ctx.sortDesc ? 'desc' : 'asc',
      query: [...ctx.filter, ...field_filters],
    }).then(result => {
      this.records = result.records
      this.group_list = result.data.map(d => {
        d._showDetails = true
        d._showItems = false
        return d
      })

      this.loading = false
      return this.group_list
    })
  }

  public toggleDetails(target: string, action: string = 'toggle') {
    if (target === 'all') {
      this.group_list.forEach(group => {
        group._showItems = action === 'show' ? true : action === 'hide' ? false : !group._showItems
      })

      return
    }
    let record = this.group_list.find((item: any) => item.user_id === target)

    if (record) {
      record._showItems = action === 'show' ? true : action === 'hide' ? false : !record._showItems
    }
  }

  // Refresh dataTable
  public refresh() {
    this.dataTable.refresh()
  }

  // Reset filters
  public resetFilters() {
    this.fieldFilters = {}
    this.query = ['is_not:archived']
    this.clearFilters()
    this.refresh()
  }

  // Store session filters in VUEX
  public syncFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'commission_rules',
      data: { query: this.query, fieldFilters: this.fieldFilters },
    })
  }

  // Load filters from VUEX if present
  public loadFilters() {
    const system = getModule(SystemtModule)
    system.getFilter('commission_rules').then((filter: any) => {
      if (filter) {
        this.query = filter.query
        this.fieldFilters = filter.fieldFilters
      }
      this.ready = true
    })
  }

  // Clear VUEX filters state
  public clearFilters() {
    const system = getModule(SystemtModule)
    system.updateState({
      name: 'filters',
      type: 'commission_rules',
      data: null,
    })
  }
}
