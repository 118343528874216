import moment from 'moment'
import Api from './Api'
import DatePickerDate from './DatePickerDate'
import WebMessage from './WebMessage'
import Model from './interface/Model'
import router from '../Routes'
import CommissionRule from './CommissionRule'

const api = new Api()
export default class CommissionRuleGroup extends Model {
  protected api_settings = {
    save_mode: 'post',
    paths: {
      singular: 'commission_rules' as string | null,
      plural: 'commission_rules' as string | null,
    },
  }

  public name: string = ''

  public user_id: string = ''

  public total_rules: number = 0

  public type: string = 'sales'

  public rules: CommissionRule[] = []

  public get apiData(): any {
    return {
      id: this.id,
      user_id: this.user_id,
    }
  }

  public toObject(source: any) {
    let instance = this.clone()
    Object.assign(instance, source)

    if (source.rules) {
      instance.rules = CommissionRule.toObjectList(source.rules)
    }

    return instance
  }

  public static checkUserComissionRules(id: string) {
    return api
      .get(`commission_rules/user/${id}`)
      .then((response: any) => response.data.result.commission_rules)
  }
}
