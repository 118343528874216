export default [
  {
    key: 'id',
    label: 'ID',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
  },
  {
    key: 'user_id',
    label: 'User',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: false,
  },
  {
    key: 'period',
    label: 'Period',
    sortable: true,
    class: 'text-center align-middle',
    show: true,
  },
  {
    key: 'product',
    label: 'Product',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'target',
    label: 'Target',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'commission',
    label: 'Commission',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'threshold',
    label: 'Threshold Frequency',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
  {
    key: 'archived_at',
    label: 'Archived At',
    sortable: true,
    class: 'text-center align-middle text-capitalize',
    show: true,
    type: 'date',
  },
  {
    key: 'action',
    label: 'Actions',
    sortable: false,
    class: 'text-center align-middle text-capitalize',
    show: true,
  },
]
