
import ViewModel from '@/models/ViewModel'
import moment from 'moment'
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component({})
export default class PreviewCommissionRule extends Vue {
  @Prop()
  public commission_rule: any

  public checkType(type: any) {
    return this.commission_rule.threshold.type === type
  }

  public formatLabel(month: number, format = 'MMM') {
    return moment()
      .month(month - 1)
      .format(format)
  }

  public get selected_target() {
    return this.commission_rule.commissionable_type
      ? this.commission_rule.commissionable_type.replace('App\\Models\\', '').toLowerCase()
      : false
  }

  public getTargetType(commission_rule: any) {
    let type = commission_rule.commissionable_type.replace('App\\Models\\', '')

    if (type === 'Company') {
      return 'Business Entity'
    }

    return type
  }
}
